import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setLanguage, setUrlLanguage } from "../reducers/languageSlice";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import ApiHook from "./CustomHooks/ApiHook";
import { selectLanguage } from "../reducers/languageSlice";
import { mobileScreen } from '../providers/constants';
import { matchUrlAndStoredLanguage } from '../hooks/customFunctions';
let checkLoader = false;

const LanguageSwitcher = () => {
	const dispatch = useDispatch();
	const [currentLanguage] = ApiHook();
	const currentLanguages = useSelector(selectLanguage);
	const savedLanguage = matchUrlAndStoredLanguage();
	const langIcon = "/assets/images/icons/sg.png";
	const { i18n } = useTranslation();
	const API = process.env.REACT_APP_API_URL + "languages";
	const [data, setData] = useState([]);
	const [name, setName] = useState(i18n.language);
	const [selectedLanguage, setSelectedLanguage] = useState(savedLanguage);
	const [isHovered, setIsHovered] = useState(false);

	const handleMouseEnter = () => {
		if(window.innerWidth >= mobileScreen) {
			setIsHovered(true);
		}
	}

	const handleMouseLeave = () => {
		if(window.innerWidth >= mobileScreen) {
			setIsHovered(false);
		}
	}

	const changeLanguage = (userData) => {
		if (userData.code) {
			i18n.changeLanguage(userData.code);
			// localStorage.setItem("i18nextLng", userData.code);
			dispatch(setUrlLanguage(userData.code));
			dispatch(setLanguage(userData.code));
			setIsHovered(false);
			setName(userData.name);
			setSelectedLanguage(userData);
		}
	}

	const getObjectByKeyCode = (items, keyCode) => {
		// Find the object in the array with the matching key code
		return items.find((item) => item.code === keyCode);
	}

	const checkTrigger = () => {
		// if(isHovered) {
		setIsHovered(!isHovered)
		// }
	}

	const apiGet = () => {
		checkLoader = true;
		fetch(API).then((response) => response.json()).then((json) => {
			checkLoader = false;
			setData(json);
			checkLoader = false;
			let selectedLanguage = getObjectByKeyCode(json, savedLanguage);
			setSelectedLanguage(selectedLanguage);
		}).catch((error) => {
			console.log("Error fetching data:", error);
			checkLoader = false;
		});
	}

	useEffect(() => {
		if(typeof selectedLanguage != 'undefined' && selectedLanguage.code) {
			localStorage.setItem("i18nextLng", selectedLanguage.code);
		}
	}, [selectedLanguage])

	useEffect(() => {
		apiGet();
	}, [API, savedLanguage]);

	return (
		<React.Fragment>
			{checkLoader && (
				<div className="language_spinner">
					<div
						className="spinner-border text-warning language_spinner"
						role="status"
					>
						<span className="sr-only">Loading...</span>
					</div>
					<span className="empty_layer"></span>
				</div>
			)}

			<div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
				<div className="selected-lang-item" onClick={checkTrigger}>
					<img src={selectedLanguage.icon} alt={selectedLanguage.name} />
					<span>{selectedLanguage.name}</span>
					{isHovered ? <FaAngleUp /> : <FaAngleDown />}
				</div>

				{isHovered && data && (
					<ul className="lang-main">
						{data.map((userData) => (
							<li key={userData.id} className="non-treatments">
								<div
									className="lang-item"
									onClick={() => changeLanguage(userData)}
								>
									<img src={userData.icon} alt={userData.name} />
									<span>{userData.name}</span>
								</div>
							</li>
						))}
					</ul>
				)}
			</div>
		</React.Fragment>
	);
}

export default LanguageSwitcher;
