import React from 'react';
import WatchIco from '../images/icons/Watch_Online_icon.jpeg';
import FacebookIco from '../images/icons/fb.png';
import MeIco from '../images/icons/me.png';
import YoutubeIco from '../images/icons/youtube.png';
import TweetIco from '../images/icons/tweet.png';
import LinkdinIco from '../images/icons/linkedin.png';
import BlogIco from '../images/icons/blog.png';
import TiktokIco from '../images/icons/tiktok-png.webp';
import InstaIco from '../images/icons/instagram.png';

const jsonData = {
	"en_SG": [
		{ "icon": WatchIco, "title": "Watch Online", "url": "/dev2?p=552182" },
		{ "icon": MeIco, "title": "Follow Us on Me", "url": "https://about.me/drkevinlau" },
		{ "icon": FacebookIco, "title": "Follow Us on facebook", "url": "https://www.facebook.com/scoliolife/" },
		{ "icon": YoutubeIco, "title": "Follow Us on Youtube", "url": "https://www.youtube.com/@scoliolife" },
		{ "icon": TweetIco, "title": "Follow Us on Twitter", "url": "https://twitter.com/scoliolife" },
		{ "icon": LinkdinIco, "title": "Follow Us on Linkedin", "url": "https://sg.linkedin.com/in/drkevinlau" },
		{ "icon": BlogIco, "title": "Follow Us on Blogspot", "url": "https://drkevinlau.blogspot.com" },
		{ "icon": InstaIco, "title": "Follow Us on Instagram", "url": "https://www.instagram.com/scoliolife/" },
		{ "icon": TiktokIco, "title": "Follow Us on Tiktok", "url": "https://www.tiktok.com/@scoliolife.en" }
	],
	"en_US": [
		{ "icon": WatchIco, "title": "Watch Online", "url": "/dev2?p=552182" },
		{ "icon": MeIco, "title": "Follow Us on Me", "url": "https://about.me/drkevinlau" },
		{ "icon": FacebookIco, "title": "Follow Us on facebook", "url": "https://www.facebook.com/scoliolife/" },
		{ "icon": YoutubeIco, "title": "Follow Us on Youtube", "url": "https://www.youtube.com/@scoliolife" },
		{ "icon": TweetIco, "title": "Follow Us on Twitter", "url": "https://twitter.com/scoliolife" },
		{ "icon": LinkdinIco, "title": "Follow Us on Linkedin", "url": "https://sg.linkedin.com/in/drkevinlau" },
		{ "icon": BlogIco, "title": "Follow Us on Blogspot", "url": "https://drkevinlau.blogspot.com" },
		{ "icon": InstaIco, "title": "Follow Us on Instagram", "url": "https://www.instagram.com/scoliolife/" },
		{ "icon": TiktokIco, "title": "Follow Us on Tiktok", "url": "https://www.tiktok.com/@scoliolife.en" }
	],
	"en_UK": [
		{ "icon": WatchIco, "title": "Watch Online", "url": "/dev2?p=552182" },
		{ "icon": MeIco, "title": "Follow Us on Me", "url": "https://about.me/drkevinlau" },
		{ "icon": FacebookIco, "title": "Follow Us on facebook", "url": "https://www.facebook.com/scoliolife/" },
		{ "icon": YoutubeIco, "title": "Follow Us on Youtube", "url": "https://www.youtube.com/@scoliolife" },
		{ "icon": TweetIco, "title": "Follow Us on Twitter", "url": "https://twitter.com/scoliolife" },
		{ "icon": LinkdinIco, "title": "Follow Us on Linkedin", "url": "https://sg.linkedin.com/in/drkevinlau" },
		{ "icon": BlogIco, "title": "Follow Us on Blogspot", "url": "https://drkevinlau.blogspot.com" },
		{ "icon": InstaIco, "title": "Follow Us on Instagram", "url": "https://www.instagram.com/scoliolife/" },
		{ "icon": TiktokIco, "title": "Follow Us on Tiktok", "url": "https://www.tiktok.com/@scoliolife.en" }
	],
	"en_CA": [
		{ "icon": WatchIco, "title": "Watch Online", "url": "/dev2?p=552182" },
		{ "icon": MeIco, "title": "Follow Us on Me", "url": "https://about.me/drkevinlau" },
		{ "icon": FacebookIco, "title": "Follow Us on facebook", "url": "https://www.facebook.com/scoliolife/" },
		{ "icon": YoutubeIco, "title": "Follow Us on Youtube", "url": "https://www.youtube.com/@scoliolife" },
		{ "icon": TweetIco, "title": "Follow Us on Twitter", "url": "https://twitter.com/scoliolife" },
		{ "icon": LinkdinIco, "title": "Follow Us on Linkedin", "url": "https://sg.linkedin.com/in/drkevinlau" },
		{ "icon": BlogIco, "title": "Follow Us on Blogspot", "url": "https://drkevinlau.blogspot.com" },
		{ "icon": InstaIco, "title": "Follow Us on Instagram", "url": "https://www.instagram.com/scoliolife/" },
		{ "icon": TiktokIco, "title": "Follow Us on Tiktok", "url": "https://www.tiktok.com/@scoliolife.en" }
	],
	"en_IN": [
		{ "icon": WatchIco, "title": "Watch Online", "url": "/dev2?p=552182" },
		{ "icon": MeIco, "title": "Follow Us on Me", "url": "https://about.me/drkevinlau" },
		{ "icon": FacebookIco, "title": "Follow Us on facebook", "url": "https://www.facebook.com/scoliolife/" },
		{ "icon": YoutubeIco, "title": "Follow Us on Youtube", "url": "https://www.youtube.com/@scoliolife" },
		{ "icon": TweetIco, "title": "Follow Us on Twitter", "url": "https://twitter.com/scoliolife" },
		{ "icon": LinkdinIco, "title": "Follow Us on Linkedin", "url": "https://sg.linkedin.com/in/drkevinlau" },
		{ "icon": BlogIco, "title": "Follow Us on Blogspot", "url": "https://drkevinlau.blogspot.com" },
		{ "icon": InstaIco, "title": "Follow Us on Instagram", "url": "https://www.instagram.com/scoliolife/" },
		{ "icon": TiktokIco, "title": "Follow Us on Tiktok", "url": "https://www.tiktok.com/@scoliolife.en" }
	],
	"en_NZ": [
		{ "icon": WatchIco, "title": "Watch Online", "url": "/dev2?p=552182" },
		{ "icon": MeIco, "title": "Follow Us on Me", "url": "https://about.me/drkevinlau" },
		{ "icon": FacebookIco, "title": "Follow Us on facebook", "url": "https://www.facebook.com/scoliolife/" },
		{ "icon": YoutubeIco, "title": "Follow Us on Youtube", "url": "https://www.youtube.com/@scoliolife" },
		{ "icon": TweetIco, "title": "Follow Us on Twitter", "url": "https://twitter.com/scoliolife" },
		{ "icon": LinkdinIco, "title": "Follow Us on Linkedin", "url": "https://sg.linkedin.com/in/drkevinlau" },
		{ "icon": BlogIco, "title": "Follow Us on Blogspot", "url": "https://drkevinlau.blogspot.com" },
		{ "icon": InstaIco, "title": "Follow Us on Instagram", "url": "https://www.instagram.com/scoliolife/" },
		{ "icon": TiktokIco, "title": "Follow Us on Tiktok", "url": "https://www.tiktok.com/@scoliolife.en" }
	],
	"en_AU": [
		{ "icon": WatchIco, "title": "Watch Online", "url": "/dev2?p=552182" },
		{ "icon": MeIco, "title": "Follow Us on Me", "url": "https://about.me/drkevinlau" },
		{ "icon": FacebookIco, "title": "Follow Us on facebook", "url": "https://www.facebook.com/scoliolife/" },
		{ "icon": YoutubeIco, "title": "Follow Us on Youtube", "url": "https://www.youtube.com/@scoliolife" },
		{ "icon": TweetIco, "title": "Follow Us on Twitter", "url": "https://twitter.com/scoliolife" },
		{ "icon": LinkdinIco, "title": "Follow Us on Linkedin", "url": "https://sg.linkedin.com/in/drkevinlau" },
		{ "icon": BlogIco, "title": "Follow Us on Blogspot", "url": "https://drkevinlau.blogspot.com" },
		{ "icon": InstaIco, "title": "Follow Us on Instagram", "url": "https://www.instagram.com/scoliolife/" },
		{ "icon": TiktokIco, "title": "Follow Us on Tiktok", "url": "https://www.tiktok.com/@scoliolife.en" }
	],
	"en_MY": [
		{ "icon": WatchIco, "title": "Watch Online", "url": "/dev2?p=552182" },
		{ "icon": MeIco, "title": "Follow Us on Me", "url": "https://about.me/drkevinlau" },
		{ "icon": FacebookIco, "title": "Follow Us on facebook", "url": "https://www.facebook.com/scoliolife/" },
		{ "icon": YoutubeIco, "title": "Follow Us on Youtube", "url": "https://www.youtube.com/@scoliolife" },
		{ "icon": TweetIco, "title": "Follow Us on Twitter", "url": "https://twitter.com/scoliolife" },
		{ "icon": LinkdinIco, "title": "Follow Us on Linkedin", "url": "https://sg.linkedin.com/in/drkevinlau" },
		{ "icon": BlogIco, "title": "Follow Us on Blogspot", "url": "https://drkevinlau.blogspot.com" },
		{ "icon": InstaIco, "title": "Follow Us on Instagram", "url": "https://www.instagram.com/scoliolife/" },
		{ "icon": TiktokIco, "title": "Follow Us on Tiktok", "url": "https://www.tiktok.com/@scoliolife.en" }
	],
	"es_ES": [
		{ "icon": WatchIco, "title": "Watch Online", "url": "https://es.scoliolife.com" },
		{ "icon": MeIco, "title": "Follow Us on Me", "url": "https://about.me/drkevinlau" },
		{ "icon": FacebookIco, "title": "Follow Us on facebook", "url": "https://www.facebook.com/scoliolife.es" },
		{ "icon": YoutubeIco, "title": "Follow Us on Youtube", "url": "https://www.youtube.com/@scoliolife_es" },
		{ "icon": TweetIco, "title": "Follow Us on Twitter", "url": "https://twitter.com/scoliolife" },
		{ "icon": LinkdinIco, "title": "Follow Us on Linkedin", "url": "https://www.linkedin.com/company/scoliolife-es" },
		{ "icon": BlogIco, "title": "Follow Us on Blogspot", "url": "https://drkevinlau.blogspot.com" },
		{ "icon": InstaIco, "title": "Follow Us on Instagram", "url": "https://www.instagram.com/scoliolife.es" },
		{ "icon": TiktokIco, "title": "Follow Us on Tiktok", "url": "https://www.tiktok.com/@scoliolife.es" }
	],
	"es_MX": [
		{ "icon": WatchIco, "title": "Watch Online", "url": "https://es.scoliolife.com" },
		{ "icon": MeIco, "title": "Follow Us on Me", "url": "https://about.me/drkevinlau" },
		{ "icon": FacebookIco, "title": "Follow Us on facebook", "url": "https://www.facebook.com/scoliolife.es" },
		{ "icon": YoutubeIco, "title": "Follow Us on Youtube", "url": "https://www.youtube.com/@scoliolife_es" },
		{ "icon": TweetIco, "title": "Follow Us on Twitter", "url": "https://twitter.com/scoliolife" },
		{ "icon": LinkdinIco, "title": "Follow Us on Linkedin", "url": "https://www.linkedin.com/company/scoliolife-es" },
		{ "icon": BlogIco, "title": "Follow Us on Blogspot", "url": "https://drkevinlau.blogspot.com" },
		{ "icon": InstaIco, "title": "Follow Us on Instagram", "url": "https://www.instagram.com/scoliolife.es" },
		{ "icon": TiktokIco, "title": "Follow Us on Tiktok", "url": "https://www.tiktok.com/@scoliolife.es" }
	],
	"id_ID": [
		{ "icon": WatchIco, "title": "Watch Online", "url": "https://id.scoliolife.com" },
		{ "icon": MeIco, "title": "Follow Us on Me", "url": "https://about.me/drkevinlau" },
		{ "icon": FacebookIco, "title": "Follow Us on facebook", "url": "https://www.facebook.com/scoliolife.id" },
		{ "icon": YoutubeIco, "title": "Follow Us on Youtube", "url": "https://www.youtube.com/@scoliolife_id" },
		{ "icon": TweetIco, "title": "Follow Us on Twitter", "url": "https://twitter.com/scoliolife" },
		{ "icon": LinkdinIco, "title": "Follow Us on Linkedin", "url": "https://www.linkedin.com/company/scoliolife-id" },
		{ "icon": BlogIco, "title": "Follow Us on Blogspot", "url": "https://drkevinlau.blogspot.com" },
		{ "icon": InstaIco, "title": "Follow Us on Instagram", "url": "https://www.instagram.com/scoliolife.id" },
		{ "icon": TiktokIco, "title": "Follow Us on Tiktok", "url": "https://www.tiktok.com/@scoliolife.id" }
	],
	"de_DE": [
		{ "icon": WatchIco, "title": "Watch Online", "url": "https://de.scoliolife.com" },
		{ "icon": MeIco, "title": "Follow Us on Me", "url": "https://about.me/drkevinlau" },
		{ "icon": FacebookIco, "title": "Follow Us on facebook", "url": "https://www.facebook.com/scoliolife.de/" },
		{ "icon": YoutubeIco, "title": "Follow Us on Youtube", "url": "https://www.youtube.com/@scoliolife_de" },
		{ "icon": TweetIco, "title": "Follow Us on Twitter", "url": "https://twitter.com/scoliolife" },
		{ "icon": LinkdinIco, "title": "Follow Us on Linkedin", "url": "https://www.linkedin.com/company/scoliolife-de" },
		{ "icon": BlogIco, "title": "Follow Us on Blogspot", "url": "https://drkevinlau.blogspot.com" },
		{ "icon": InstaIco, "title": "Follow Us on Instagram", "url": "https://www.instagram.com/scoliolife.de/" },
		{ "icon": TiktokIco, "title": "Follow Us on Tiktok", "url": "https://www.tiktok.com/@scoliolife.de" }
	],
	"it_IT": [
		{ "icon": WatchIco, "title": "Watch Online", "url": "https://it.scoliolife.com" },
		{ "icon": MeIco, "title": "Follow Us on Me", "url": "https://about.me/drkevinlau" },
		{ "icon": FacebookIco, "title": "Follow Us on facebook", "url": "https://www.facebook.com/scoliolife.it" },
		{ "icon": YoutubeIco, "title": "Follow Us on Youtube", "url": "https://www.youtube.com/@scoliolife_it" },
		{ "icon": TweetIco, "title": "Follow Us on Twitter", "url": "https://twitter.com/scoliolife" },
		{ "icon": LinkdinIco, "title": "Follow Us on Linkedin", "url": "https://www.linkedin.com/company/scoliolife-it" },
		{ "icon": BlogIco, "title": "Follow Us on Blogspot", "url": "https://drkevinlau.blogspot.com" },
		{ "icon": InstaIco, "title": "Follow Us on Instagram", "url": "https://www.instagram.com/scoliolife.it/" },
		{ "icon": TiktokIco, "title": "Follow Us on Tiktok", "url": "https://www.tiktok.com/@scoliolife.it" }
	],
	"zh_CN": [
		{ "icon": WatchIco, "title": "Watch Online", "url": "https://cn.scoliolife.com" },
		{ "icon": MeIco, "title": "Follow Us on Me", "url": "https://about.me/drkevinlau" },
		{ "icon": FacebookIco, "title": "Follow Us on facebook", "url": "https://www.facebook.com/scoliolife.cn/" },
		{ "icon": YoutubeIco, "title": "Follow Us on Youtube", "url": "https://www.youtube.com/@scoliolife_cn" },
		{ "icon": TweetIco, "title": "Follow Us on Twitter", "url": "https://twitter.com/scoliolife" },
		{ "icon": LinkdinIco, "title": "Follow Us on Linkedin", "url": "https://www.linkedin.com/company/scoliolife-cn" },
		{ "icon": BlogIco, "title": "Follow Us on Blogspot", "url": "https://drkevinlau.blogspot.com" },
		{ "icon": InstaIco, "title": "Follow Us on Instagram", "url": "https://www.instagram.com/scoliolife.cn" },
		{ "icon": TiktokIco, "title": "Follow Us on Tiktok", "url": "https://www.tiktok.com/@scoliolife.cn" }
	],
	"zh_HK": [
		{ "icon": WatchIco, "title": "Watch Online", "url": "https://hk.scoliolife.com" },
		{ "icon": MeIco, "title": "Follow Us on Me", "url": "https://about.me/drkevinlau" },
		{ "icon": FacebookIco, "title": "Follow Us on facebook", "url": "https://www.facebook.com/scoliolife.hk" },
		{ "icon": YoutubeIco, "title": "Follow Us on Youtube", "url": "https://www.youtube.com/@scoliolife_hk" },
		{ "icon": TweetIco, "title": "Follow Us on Twitter", "url": "https://twitter.com/scoliolife" },
		{ "icon": LinkdinIco, "title": "Follow Us on Linkedin", "url": "https://www.linkedin.com/company/scoliolife-hk" },
		{ "icon": BlogIco, "title": "Follow Us on Blogspot", "url": "https://drkevinlau.blogspot.com" },
		{ "icon": InstaIco, "title": "Follow Us on Instagram", "url": "https://www.instagram.com/scoliolife.hk" },
		{ "icon": TiktokIco, "title": "Follow Us on Tiktok", "url": "https://www.tiktok.com/@scoliolife.hk" }
	],
	"ja_JP": [
		{ "icon": WatchIco, "title": "Watch Online", "url": "https://jp.scoliolife.com" },
		{ "icon": MeIco, "title": "Follow Us on Me", "url": "https://about.me/drkevinlau" },
		{ "icon": FacebookIco, "title": "Follow Us on facebook", "url": "https://www.facebook.com/scoliolife.jp" },
		{ "icon": YoutubeIco, "title": "Follow Us on Youtube", "url": "https://www.youtube.com/@scoliolife_jp" },
		{ "icon": TweetIco, "title": "Follow Us on Twitter", "url": "https://twitter.com/scoliolife" },
		{ "icon": LinkdinIco, "title": "Follow Us on Linkedin", "url": "https://www.linkedin.com/company/scoliolife-jp" },
		{ "icon": BlogIco, "title": "Follow Us on Blogspot", "url": "https://drkevinlau.blogspot.com" },
		{ "icon": InstaIco, "title": "Follow Us on Instagram", "url": "https://www.instagram.com/scoliolife.jp" },
		{ "icon": TiktokIco, "title": "Follow Us on Tiktok", "url": "https://www.tiktok.com/@scoliolife.jp" }
	]
}

export {
	jsonData
}