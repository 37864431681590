import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripeLive, stripeDev } from './constants';

// Replace 'your_publishable_key' with your actual publishable key
// const stripePromise = loadStripe(stripeDev);
const stripePromise = loadStripe(stripeLive);

const StripeProvider = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeProvider;
