import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';


const MetaCreator = (props) => {
	const { title = '', description = '', tags = '' } = props;
    let pageUrl = window.location.href;
	return (
		<Helmet>
            {(title) ? <title>{title}</title> : null}
            {(description) ? <meta name="description" content={description} /> : null}
            {(tags) ? <meta name="keywords" content={tags} /> : null}
            <link rel="canonical" href={pageUrl} />
        </Helmet>
	)
}

export default MetaCreator;