const mobileScreen = 768;
// google cloud platfrom details by client

//const googleClientId = '577793062457-7rhqd6k1pso814kdm50irrpg9hij9ug2.apps.googleusercontent.com';
//const googleClientSecret = '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';

// google cloud platfrom details by srbjit rnd
const googleClientId = '994777588132-9uqrbdpvio1rt77qbhkfnhmfmeqh96a2.apps.googleusercontent.com';
const googleClientSecret = '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';

// const paypalSandbox = 'AcZoLbcXiBkKeUmhjPJn39Y9UHKX7pvsQ44gU28DjJlMv15vpPxZrlSR_eJQW6r9QDtp-Q-bPQoTlehr';
// const paypalSandboxSecret = 'EL8z0NO5T8XB4Fwk4GtYk_boyMxG-VSALyk8PygRxaLLuyGEIEKx9EbWPfK39f9gpBWcgusvymnU-CLA';
// const paypalSandbox = 'AURuZSooCilWtZ1ArKmVfivs8spN2d9EBhfWANYDdsrZmtOAuIRhu0vK5pVlm_LxfYbmqeVoj04mat1M';
const paypalSandbox = 'AcZoLbcXiBkKeUmhjPJn39Y9UHKX7pvsQ44gU28DjJlMv15vpPxZrlSR_eJQW6r9QDtp-Q-bPQoTlehr';
// const paypalSandboxSecret = 'EBkxyt_v0ybYuKEl_bK6JnAZZjmTObm9u8t7lZxxjspjj2siBEo5cyOYkWuJH9hujWCA5beokUIoU8II';
const paypalSandboxSecret = 'EL8z0NO5T8XB4Fwk4GtYk_boyMxG-VSALyk8PygRxaLLuyGEIEKx9EbWPfK39f9gpBWcgusvymnU-CLA';

const paypalLive = 'Ae_2k1GIJHEET6FZMSV1Ff5rYY8y-Tj_4Z5wR0PzHI6rohb86WRVVnUPSgSXUi7rEG5ehqmMMhAAfC_F';
const paypalLiveSecret = 'EKt932Lra0K-UnRVmlmGHwJaFjqAp60t45dTzc6E3c2W6X3W8vQrMUw9itBzvn18faG2aHLkA5lNvDDq';

const stripeLive = 'pk_live_amb9NAejXk5MbNs31qIXi3V4';
const stripeDev = 'pk_test_bsziAY1JbgHC4AAlGEdG9ugb'

const appointmentCalander = 'https://calendar.app.google/E9hQztUZ2S9gQJEZ7';


const paypalApiUrl = 'https://api-m.paypal.com'
const paypalSandboxUrl = 'https://api-m.sandbox.paypal.com'

export {
	mobileScreen,
	googleClientId,
	googleClientSecret,
	paypalSandbox,
	paypalSandboxSecret,
	appointmentCalander,
	stripeLive,
	stripeDev,
	paypalLive,
	paypalLiveSecret,
	paypalApiUrl,
	paypalSandboxUrl,
}