import React, { useState, useEffect } from 'react';
import './ContactUs.css';
// import { useSelector } from 'react-redux';
// import { selectLanguage } from '../reducers/languageSlice';
import CalendarSchedulingButton from '../components/CalendarSchedulingButton';
import ApiHook from '../components/CustomHooks/ApiHook';
import ContactComponent from '../components/ContactComponent';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useDynamicTitle from '../hooks/useDynamicTitle';
import TopBanner from '../components/TopBanner';
// import ReactWhatsapp from 'react-whatsapp';

const ContactUs = () => {
  const [currentLanguage, urlLanguage] = ApiHook();
  let languageCode
  const navigate = useNavigate();
  const { t } = useTranslation();

 
  useEffect(() => {
    navigate(`${urlLanguage}/contact-us`)
  }, [currentLanguage, navigate, urlLanguage])

  // const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    // Load Google Calendar appointment scheduling script only if not loaded already
    if (!isScriptLoaded) {
      const script = document.createElement('script');
      script.src = 'https://calendar.google.com/calendar/scheduling-button-script.js';
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        setIsScriptLoaded(true);
      };

      return () => {
        // Cleanup: remove the script when the component unmounts
        document.head.removeChild(script);
      };
    }
  }, [isScriptLoaded]);

  switch (currentLanguage) {
    case 'en_SG':
      languageCode = 'EN';
      break;
    case 'es_ES':
      languageCode = 'ES';
      break;
    case 'fr_FR':
      languageCode = 'FR';
      break;
    case 'id_ID':
      languageCode = 'ID';
      break;
    case 'it_IT':
      languageCode = 'IT';
      break;
    case 'es_MX':
      languageCode = 'ES';
      break;
    case 'de_DE':
      languageCode = 'DE';
      break;
    case 'zh_CN':
      languageCode = 'CN';
      break;
    case 'zh_HK':
      languageCode = 'HK';
      break;
    case 'ja_JP':
      languageCode = 'JP';
      break;
    default:
      languageCode = 'EN';
    // Default code for unknown language
  }
  const facebookPageURL = `https://sladmin.scoliolife.com/uploads/2022/07/Contact-us-${languageCode}.png`;

  useDynamicTitle(t("contactUs.CONTACT US"));

  return (
    <>
      <TopBanner title={t("contactUs.CONTACT US")} />

      <div className="contact_row_section">
        <div className="container">
          <div className="contact_deatails_section">
            <div className="contact_item_content">
              <ul className="contact_box">
                <li className="item-content clearfix">
                  <span className="hexagon">  <i className="fa fa-map-o" aria-hidden="true"></i></span>
                  <p></p>
                  <div className="text">
                    <h3>
                      <span title="Main Office">{t("contactUs.Main Office")}</span>
                    </h3>
                    <p>Tong Building  302 Orchard Road #10-02  Singapore 238862 </p>
                    <p></p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="contact_item_content">
              <ul className="contact_box">
                <li className="item-content clearfix">
                  <span className="hexagon"> <i className="fa fa-phone" aria-hidden="true"></i>
                  </span>
                  <p></p>
                  <div className="text">
                    <h3>
                      <span title="Call Today">{t("contactUs.Call Today")}</span>
                    </h3>
                    <p><a href="tel:+6566352550">(+65) 6635 2550</a> <a href="https://api.whatsapp.com/send?phone=6589078900">(+65) 8907 8900</a> </p>
                    <p></p>
                  </div>
                </li >
              </ul >
            </div >
            <div className="contact_item_content">
              <ul className="contact_box">
                <li className="item-content clearfix">
                  <span className="hexagon"> <i className="fa fa-clock-o" aria-hidden="true"></i></span>
                  <p></p>
                  <div className="text">
                    <h3>
                      <span title="Business Hours">{t("contactUs.Business Hours")}</span>
                    </h3>
                    <p>Monday – Friday: 08.00AM – 18.00PM  Saturday: 09.00AM – 1.00AM Sunday: Closed </p>
                    <p></p>
                  </div>
                </li >
              </ul >
            </div >
            <p></p>
          </div >
        </div >
      </div >


      <div className="contact-section">
        <div className="container">
          <div className="new-design">
            <h1 className="Bone-Spur">
              <span>{t("form.General Information Request")}</span></h1>
            <h3 className="box-header animation-slide slide">{t("form.Online Appointment Form")}</h3>
            <div className="enquiry-hidden business-form ">
              <div className="enquiry-form">

                <ContactComponent />

              </div>
            </div>
          </div>
          <div className="form-page">
            <h3 className="box-header cmain-address animation-slide slide">ScolioLife Clinic</h3>
            <div className="contact_img">
              <img decoding="async" src={facebookPageURL} alt={facebookPageURL} />
            </div>

            <div className="address">
              {isScriptLoaded && <CalendarSchedulingButton />}
              {/* <button className="qxCTlb" style={{ color: "rgb(255, 255, 255)", backgroundColor: "rgb(239, 108, 0)" }}>Book an Appointment</button> */}
             
              <p></p>
              <ul className="contact-data">
                <li className="clearfix social-location info-map">
                  <i className="fa fa-map-o" aria-hidden="true"></i>
                  <div className="value">Tong Building  302 Orchard Road #10-02 Singapore 238862 </div>
                </li>
                <li className="clearfix social-mobile info-map">
                  <i className="fa fa-mobile" aria-hidden="true"></i>
                  <div className="value">
                    <a href="tel:66352550">(+65) 6635 2550</a>
                    <a href="https://wa.me/89078900">, (+65) 8907 8900</a>
                  </div>
                </li>
                <li className="clearfix social-email info-map">
                  <i className="fa fa-envelope-o" aria-hidden="true"></i>
                  <div className="value">{t("form.Online Appointment:")}
                  {currentLanguage === 'en_SG' ? <a href="mailto:clinic.sg@scoliolife.com">clinic.sg@scoliolife.com</a> :<a href="mailto:clinic@scoliolife.com">clinic@scoliolife.com</a>}

                    {/* <a href="mailto:clinic@scoliolife.com"> clinic@scoliolife.com</a> */}
                  </div>
                </li>
                <li className="clearfix social-facebook info-map">
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                  <div className="value">Facebook: 
                    <a href="https://www.facebook.com/ScolioLife" target="blank" rel="noopener" > facebook.com/ScolioLife</a>
                  </div>
                </li>
                <li className="clearfix social-twitter info-map">
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                  <div className="value">Twitter:  
                    <a href="https://twitter.com/scolioLife" target="blank" rel="noopener"> twitter.com/scolioLife</a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="contact_quote">
              <i className="fa fa-quote-right" aria-hidden="true"></i>
              <h3 className="sentence template-quote-2 animated-element animation-slideLeft50 slideLeft50">{t("form.To inspire innovative solutions and promote strategies that creates health independence for scoliosis sufferers..")}</h3>
              <div className="clearfix">
                <span className="sentence-author animated-element animation-slideLeft50 delay-600 slideLeft50">— Dr Kevin Lau</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="map-section">
        <div className="gmap">
          <iframe title="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7850952266886!2d103.83273671432605!3d1.30397096208541!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19923d7b4249%3A0xc0febfc7d057c4a!2sTong+Building%2C+302+Orchard+Rd%2C+238862!5e0!3m2!1sen!2sin!4v1457352737924" frameBorder="0" allowFullScreen="allowFullScreen"></iframe>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
