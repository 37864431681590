// /reducers/languageSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { matchUrlAndStoredLanguage } from '../hooks/customFunctions';

const defaultLanguage = matchUrlAndStoredLanguage();
const defaultUrlLanguage = (defaultLanguage == 'en_US') ? '' : `/${defaultLanguage}`;

const languageSlice = createSlice({
  name: 'language',
  initialState: {
    currentLanguage: defaultLanguage,
    urlLanguage: defaultUrlLanguage,
  },
  reducers: {
    setLanguage: (state, action) => {
      state.currentLanguage = action.payload;
    },
    setUrlLanguage: (state, action) => {
      state.urlLanguage = (action.payload == 'en_US') ? '' : `/${action.payload}`;
    },
  },
});

export const { setLanguage, setUrlLanguage } = languageSlice.actions;
export const selectLanguage = (state) => state.language.currentLanguage;
export const selectUrlLanguage = (state) => state.language.urlLanguage;
export default languageSlice.reducer;
