import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDynamicLanguage } from '../i18n';
import LanguageSwitcher from './LanguageSwitcher';
import MainNavbar from './MainNavbar';
import { useForm } from 'react-hook-form';
import { subscribeEmail } from '../Api';
import ApiHook from './CustomHooks/ApiHook';
import { scrollToTop } from './Helper';

import { AiOutlinePhone } from "react-icons/ai";
import { FaWhatsapp } from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import { MdOutlineMail } from "react-icons/md";
import { GoMail } from "react-icons/go";
import { CiLocationOn } from "react-icons/ci";
import { appointmentCalander } from '../providers/constants';

export default function MainHeader() {
    const [currentLanguage, urlLanguage] = ApiHook();
    const { t } = useTranslation();
    useDynamicLanguage();

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const subscribe = async (data) => {
        try {
            const subscribedData = await subscribeEmail(data)
            reset();
        } catch (error) {
            console.log("errror", error);
        }
    }

    return (
        <>
            {/* top header start here*/}
            {/* <AiOutlinePhone/> */}
            {/* <CiMail/> */}
            {/* <CiLocationOn/> */}
            {/* <MdOutlineMail/> */}
            {/* <GoMail/> */}

            <div className="top-header">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-7">
                            <p>{t('top-header.para')}</p>
                        </div>
                        <div className="col-sm-5">
                            <section className="news-letter" id="News-letter">
                                <div className="news ">
                                    <form className='top-button' onSubmit={handleSubmit(subscribe)}>
                                        <input type="email" id="subsrcibe_email"{...register('subsrcibe_email', { required: "Please enter your email." })} maxLength="50" required placeholder={t("top-header.Your email here....")} />
                                        {/* {errors.subsrcibe_email && <p>Email is required.</p>} */}
                                        <button type='submit' className="bt">{t('top-header.Subscribe')}</button>
                                    </form>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            {/* top header end here*/}

            {/* middle header start here */}
            <div className="middle-header">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 left_data">
                            <div className="header-details">

                            <div className="header-number-wrapper cll">
                                    <div className="mobile-icon">
                                        <FaWhatsapp/>
                                    </div>
                                    <a target="_blank" href="https://wa.me/6589078900">(+65) 8907 8900</a>
                                </div>

                                <div className="header-number-wrapper">
                                    {/* <AiOutlinePhone/> */}
                                    <div className="mobile-icon">
                                        {/* <img src='/assets/images/phone icon.webp' alt="" /> */}
                                        <AiOutlinePhone/>
                                        </div>
                                    <a href="tel:(+65) 6635 2550">(+65) 6635 2550</a>
                                </div>
                                <div className="header-email-wrapper">
                                    <div className="email-icon">
                                        {/* <img src='/assets/images/email icon.webp' alt="" /> */}
                                        <CiMail/>
                                        </div>
                                        {currentLanguage === 'en_SG' ? <a href="mailto:clinic.sg@scoliolife.com">clinic.sg@scoliolife.com</a> :<a href="mailto:clinic@scoliolife.com">clinic@scoliolife.com</a>}
                                    {/* <a href="mailto:clinic@scoliolife.com">clinic@scoliolife.com</a> */}
                                </div>
                                <div className="header-location-wrapper">
                                    <div className="location-icon">
                                        {/* <img src='/assets/images/location icon.webp' alt="" /> */}
                                        <CiLocationOn/>
                                        </div>
                                        <a href="https://www.google.com/maps/place/Scoliosis+%26+Spine+Correction+Clinic,+Health+in+Your+Hands,+Dr.+Kevin+Lau/@1.3040545,103.8326609,17z/data=!3m1!4b1!4m5!3m4!1s0x31da19923dade207:0xcfa5a744a9975033!8m2!3d1.3040491!4d103.8348496" target="_blank" rel="noopener">302 Orchard Road #10-02, Singapore 238862</a>
                                    {/* <a href="tel:(+65) 6635 2550">302 Orchard Road #10-02A, Singapore 23886</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 right_data">
                            <div className="right-btn-wrapper">
                                <div className='book_consultation'>
                                    <Link
                                        rel="noopener noreferrer"
                                        to={'/online-booking'}
                                        
                                    >
                                        <span>
                                            <img src='/assets/images/consultation-icon.webp' alt='consultation-icon' />
                                        </span>
                                        {t('top-header.Book Consultation')}
                                    </Link>
                                </div>
                                <div className="js">
                                    <div className="language-picker js-language-picker" data-trigger-classname="btn btn--subtle">
                                        <LanguageSwitcher />
                                    </div>
                                </div>
                                <Link to={`${urlLanguage}/shop`} className="shop-btn" onClick={scrollToTop}><span><img src='/assets/images/shop icon.webp' alt='shop icon' /></span>{t("top-header.Shop")}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MainNavbar />
        </>
    )
}
